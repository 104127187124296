import { FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';
// @ts-ignore
import { Provider } from '@preact/prerender-data-provider';

import Home from '../routes/home/Home';
import Header from './header/Header';
import Footer from './footer/Footer';
import RequestDemo from '../routes/request-demo/RequestDemo';
import { CONFIG } from '../config';

const App: FunctionalComponent = (props) => {

    let paths: any = {
        home: '/',
        requestDemo: '/request-demo'
    }

    if (typeof window !== 'undefined') {
        paths.home = CONFIG.PUBLIC_PATH;
        paths.requestDemo = CONFIG.PUBLIC_PATH + 'request-demo';
    }

    return (
        <Provider value={props}>
            <main role='main'>
                <Header />
                <Router>
                    <Route path={paths.home} component={Home} />
                    <Route path={paths.requestDemo} component={RequestDemo} />
                </Router>
                <Footer />
            </main>
        </Provider>
    );
};

export default App;
