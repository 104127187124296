import { FunctionalComponent, h } from 'preact';
import style from './MainSection.scss';

interface Props {
    hideAction?: boolean;
    imageClass?: string;
}

const MainSection: FunctionalComponent<Props> = (props: Props) => {
    return (
        <section className={'bg-primary px-4 lg:px-0 py-10 h-63 lg:h-107.5 ' + style['main-section']}>
            <div class={'main-container flex items-center h-full'}>
                <div class={'flex flex-col justify-around'}>
                    <h1 className={'text-white text-3xl lg:text-13 lg:leading-15.5 font-light'}>
                        Automatic dieboard <br class={'hidden lg:inline-block'}/>
                        cost estimations
                    </h1>

                    {props.hideAction ? null : <a href="request-demo" className={'w-50 lg:w-63 h-12 lg:h-14.5 text-xl btn mt-4 lg:mt-23.5'}>Start Demo</a>}
                </div>
            </div>

        </section>
    );
};

export default MainSection;
