import { FunctionalComponent, h } from 'preact';
import { urlFormat } from '../../utils/url-format';

const ThankYou: FunctionalComponent = () => {
    return (
        <section class={'my-4 px-4 lg:px-0 lg:my-15 lg:max-w-190 lg:mx-auto flex flex-col'}>
            <h2 class={'text-center text-base-heading text-2xl lg:text-7'}>Thank you for submitting the Demo request form.</h2>
            <p class={'text-center text-base-heading text-base lg:text-lg mt-4'}>We will get back to you within the next few hours.</p>

            <div class={'my-7'}>
                <img className={'w-35 lg:w-50 mx-auto'} src={urlFormat('assets/check.svg')} alt="success icon"/>
            </div>

            <div class={'flex justify-center'}>
                <a href={urlFormat('')} className={'w-63 h-14.5 text-xl btn border-primary text-primary hover:bg-primary hover:text-white'}>Back To Site</a>
            </div>
        </section>
    );
};

export default ThankYou;
