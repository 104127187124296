import { FunctionalComponent, h } from 'preact';

interface Props {
    title: string;
    texts: string[];
    class?: string;
}

const ImageSectionText: FunctionalComponent<Props> = (props: Props) => {
    return (
        <div className={'text-base flex flex-col justify-center ' + (props.class || '')}>
            <h2 className={'text-2xl lg:text-7 text-base-heading mb-4 lg:mb-5.5 leading-7 lg:leading-8'}>{props.title}</h2>
            <div className={'text-sm lg:text-base space-y-3 lg:space-y-4.5'}>
                {props.texts.map((p: string) => {
                    return (
                        <p className={'leading-4.75'}>{p}</p>
                    )
                })}
            </div>
        </div>

    );
};

export default ImageSectionText;