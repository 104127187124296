import { FunctionalComponent, h } from 'preact';

interface Props {
    left: any;
    right: any;
    class?: string;
}

const ImageSection: FunctionalComponent<Props> = (props: Props) => {
    return (
        <section class={'flex flex-col lg:flex-row lg:justify-between space-x-0 lg:space-x-28 ' + props.class}>
            {props.left}
            {props.right}
        </section>
    );
};

export default ImageSection;
