import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

export function VideoPlayer() {
  let [width, setWidth] = useState(560);

  useEffect(() => {
    if (typeof screen !== 'undefined') {
      setWidth(Math.min(screen.width, 560));
    }
  }, []);

  return (
      <iframe width={width} height="315" src="https://www.youtube.com/embed/2fc871GU_Xg" title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
  );
}
