import { FunctionalComponent, h } from 'preact';
import { useRef, useState } from 'preact/hooks';
import Recaptcha from '../recaptcha/Recaptcha';
import { CONFIG } from '../../config';
import style from './ContactUsForm.scss';

const initialState = {
    name: '',
    email: '',
    message: '',
    challenge: ''
};

const ContactUsForm: FunctionalComponent = () => {

    const [state, setState] = useState<any>(initialState);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const handleChange = ({ target: { type, name, value } }: any) => {
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!state.challenge?.trim().length) {
            setShowError(true);
            return;
        }

        const response = await fetch(`${CONFIG.API}/api/v1/public/contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(state)
        });

        if (response.ok) {
            clearState();
            setShowSuccess(true);
        }
    };

    const tokenChange = (challenge: string) => {
        setState((prevState: any) => ({ ...prevState, challenge }));
        setShowError(false);
    };

    const successElement = showSuccess ? <p className={'bg-success py-2 px-4 text-white my-4'}>Sent successfully</p> : null;
    const errorElement = showError ? <p className={'bg-error py-2 px-4 text-white my-4'}>Please verify that you are not a robot</p> : null;

    return (
        <section className={'bg-primary border-b-2 border-white py-11 px-4 lg:px-0'}>
            <div className={'main-container flex flex-col lg:flex-row lg:space-x-28'}>
                <form className={'lg:w-116'} onSubmit={handleSubmit}>
                    <h3 className={'text-lg text-white mb-1'}>Contact Us</h3>
                    <div className={'flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3'}>
                        <input type="text" placeholder='Name' name='name' required maxLength={200} tabIndex={1}
                               value={state.name} onChange={handleChange}
                               className={'flex-1 px-2 py-2 lg:py-1 text-sm bg-white text-form-black'} />

                        <input type="email" placeholder='Email' name='email' required maxLength={200} tabIndex={2}
                               value={state.email} onChange={handleChange} className={'flex-1 px-2 py-2 lg:py-1 text-sm bg-white text-form-black'} />
                    </div>
                    <textarea name='message' value={state.message} onChange={handleChange} className={'mt-3 w-full px-2 py-1 text-sm bg-white text-form-black'} required maxLength={500} rows={7} tabIndex={3} placeholder='Message'/>
                    <Recaptcha onChange={tokenChange} />
                    {successElement}
                    {errorElement}
                    <button type={'submit'} className={'mt-2 w-38.5 h-10 lg:h-8.5 text-sm btn'} tabIndex={4}>Send</button>
                </form>
                <div className={'text-white flex-1 mt-6'}>
                    <p className={'text-lg'}>EngView Systems</p>
                    <a href='https://www.engview.com' target='_blank' className={'hover:underline'}>www.engview.com</a>
                    <p>dieboard.calculator@engview.com</p>
                    <a href='tel:1-514-343-0290' className={'hover:underline'}>1-514-343-0290</a>
                </div>
            </div>
        </section>
    );
};

export default ContactUsForm;
