import { FunctionalComponent, h } from 'preact';
import styles from './ImageSectionImage.scss';

interface Props {
    image: string;
    class?: string;
}

const ImageSectionImage: FunctionalComponent<Props> = (props: Props) => {
    return (
        <img className={styles.image + ' ' + (props.class || '')} src={props.image} alt=""/>
    );
};

export default ImageSectionImage;