import { FunctionalComponent, h } from 'preact';
import style from './Header.scss';
import { urlFormat } from '../../utils/url-format';

const Header: FunctionalComponent = () => {

    const smoothScroll = (e: any) => {
        if (typeof window !== 'undefined') {

            const href = e.target.dataset.href;
            let element;
            if (href) {
                element = document.getElementById(href);
            }

            if (element) {
                e.preventDefault();
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    };

    return (
        <header class={'flex justify-between px-4 lg:px-0 py-3 lg:py-0 ' + style.header}>
            <div>
                <a href={urlFormat('')}>
                    <img className={'w-35 lg:w-50'} src={urlFormat("assets/logo.svg")} alt="dieboard calculator logo"/>
                </a>
            </div>
            <div class={'flex flex-col justify-between'}>
                <div class={'flex justify-end mt-1.5'}>
                    <a href="https://engview.com">
                        <img className={'w-3.5 mr-2'} src={urlFormat('assets/home.svg')} alt="home"/>
                    </a>
                    <a href="https://dieboard-calculator.engview.com/diemaker" class={'uppercase text-xs text-nav-light border-l border-black pl-2 leading-3.25'}>Login</a>
                </div>
                <nav class={'hidden lg:block -mr-8'}>
                    <a href={urlFormat('')} className={'text-sm uppercase text-nav px-8 border-b-2 border-primary pb-1.5 inline-block transition color-primary'}>Home</a>
                    <a href={urlFormat('#producttour')} data-href="producttour" onClick={smoothScroll} className={'text-sm uppercase text-nav px-8 border-transparent border-b-2 hover:border-primary pb-1.5 inline-block transition'}>Product Tour</a>
                    <a href={urlFormat('#pricing')} data-href="pricing" onClick={smoothScroll} className={'text-sm uppercase text-nav px-8 border-transparent border-b-2 hover:border-primary pb-1.5 inline-block transition'}>Pricing</a>
                    <a href={urlFormat('#contactus')} data-href="contactus" onClick={smoothScroll} className={'text-sm uppercase text-nav px-8 border-transparent border-b-2 hover:border-primary pb-1.5 inline-block transition'}>Contact Us</a>
                </nav>
            </div>

        </header>
    );
};

export default Header;
