import { createRef, FunctionalComponent, h } from 'preact';
import { useEffect, useImperativeHandle, useState } from 'preact/hooks';
import { forwardRef } from 'preact/compat';
import { CONFIG } from '../../config';

declare const grecaptcha: any;

// @ts-ignore
if(typeof window !== 'undefined' && typeof window['grecaptcha'] === 'undefined') {
    // @ts-ignore
    window['grecaptcha'] = {};
}

if (typeof window !== 'undefined') {
    grecaptcha.ready = function(cb: any){
        if(typeof grecaptcha === 'undefined') {
            // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
            // configuration. By default, any functions listed in its 'fns' property
            // are automatically executed when reCAPTCHA loads.
            const c: any = '___grecaptcha_cfg';
            window[c] = window[c] || {};
            // @ts-ignore
            (window[c]['fns'] = window[c]['fns']||[]).push(cb);
        } else {
            cb();
        }
    }
}



interface Props {
    onChange: (token: string) => void;
}

const Recaptcha: FunctionalComponent<Props> = forwardRef((props: Props, ref) => {

    const captchaRef = createRef();

    let widgetId: any;

    let onSuccess = (token: string) => {
        props.onChange(token);
    };

    useEffect(() => {

        if (typeof window !== 'undefined') {

            grecaptcha.ready(() => {
                const config = {
                    'sitekey': CONFIG.GOOGLE_SITE_KEY,
                    'callback': onSuccess
                };

                setTimeout(() => {
                    widgetId = (grecaptcha as any).render(captchaRef.current, config);
                }, 50);

            });
        }

        return () => {
            grecaptcha.reset(widgetId);
        }
    }, []);

    return (
        <div ref={captchaRef}></div>
    );
});

export default Recaptcha;
