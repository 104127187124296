import { FunctionalComponent, h } from 'preact';
import MainSection from '../../components/main-section/MainSection';
import style from './RequestDemo.scss';
import ThankYou from '../../components/thank-you/ThankYou';
import { useRef, useState } from 'preact/hooks';
import Recaptcha from '../../components/recaptcha/Recaptcha';
import { CONFIG } from '../../config';
import { VideoPlayer } from '../../components/video-player/video-player';

const initialState = {
    name: '',
    email: '',
    message: '',
    company: '',
    phone: '',
    challenge: ''
};

const RequestDemo: FunctionalComponent = () => {

    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [state, setState] = useState<any>(initialState);
    const [showError, setShowError] = useState<boolean>(false);

    const handleChange = ({ target: { type, name, value } }: any) => {
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const clearState = () => {
        setState({ ...initialState });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!state.challenge?.trim().length) {
            setShowError(true);
            return;
        }

        const response = await fetch(`${CONFIG.API}/api/v1/public/request-demo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(state)
        });

        if (response.ok) {
            clearState();
            setShowThankYou(true);
        }
    };

    const tokenChange = (challenge: string) => {
        setState((prevState: any) => ({ ...prevState, challenge }));
        setShowError(false);
    };

    const errorElement = showError ? <p className={'bg-error py-2 px-4 text-white my-4'}>Please verify that you are not a robot</p> : null;

    return (
        <div>
            <MainSection hideAction={true} imageClass={'hidden block'} />
            {showThankYou ? <ThankYou/> :
                <div className={'lg:flex lg:w-256 lg:mx-auto my-4 lg:my-15 lg:space-x-10'}>
                    <section>
                        <VideoPlayer />
                    </section>
                    <section className={'px-4 lg:px-0 mt-6 lg:mt-0 lg:max-w-102 lg:mx-auto'}>
                        <form onSubmit={handleSubmit}>
                            <h2 className={'text-base-heading text-2xl lg:text-7'}>Request Demo</h2>
                            <p className={'mt-3 lg:mt-5 text-base-heading text-sm lg:text-lg leading-5.5'}>Please fill in thе form to request an online demonstration and a demo account for evaluation. </p>
                            <div className={'space-y-2'}>
                                <div className={'mt-5 flex flex-col lg:flex-row lg:items-center'}>
                                    <label htmlFor="firstName" className={style.label}>Name:</label>
                                    <input type="text" id='firstName' className={style.input}
                                           required maxLength={200}
                                           name='name' value={state.name} onChange={handleChange}/>
                                </div>
                                <div className={'mt-5 flex flex-col lg:flex-row lg:items-center'}>
                                    <label htmlFor="email" className={style.label}>Email:</label>
                                    <input type="email" id='email' className={style.input}
                                           required maxLength={70}
                                           name='email' value={state.email} onChange={handleChange}/>
                                </div>
                                <div className={'mt-5 flex flex-col lg:flex-row lg:items-center'}>
                                    <label htmlFor="company" className={style.label}>Company:</label>
                                    <input type="text" id='company' className={style.input}
                                           required maxLength={200}
                                           name='company' value={state.company} onChange={handleChange}/>
                                </div>
                                <div className={'mt-5 flex flex-col lg:flex-row lg:items-center'}>
                                    <label htmlFor="phone" className={style.label}>Phone:</label>
                                    <input type="text" id='phone' className={style.input}
                                           required maxLength={60}
                                           name='phone' value={state.phone} onChange={handleChange}/>
                                </div>

                                <div className={'mt-5 flex flex-col lg:flex-row'}>
                                    <label htmlFor="message" className={'pt-3 ' + style.label}>Message:</label>
                                    <textarea name='message' value={state.subject} onChange={handleChange}
                                              className={style.input} required
                                              maxLength={500} rows={7}></textarea>
                                </div>

                                <div className={'flex lg:justify-end'}>
                                    <div className={'w-75'}>
                                        <Recaptcha onChange={tokenChange} />
                                    </div>
                                </div>

                                {errorElement}

                                <div className={'flex lg:justify-end '}>
                                    <div className={'w-full lg:w-75 mt-3 lg:mt-5 mb-4 lg:mb-0'}>
                                        <button type='submit' className={'w-full lg:w-38.5 h-10 lg:h-8.5 text-sm btn bg-primary text-white border-primary hover:border-primary hover:text-primary'}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            }
        </div>
    );
};

export default RequestDemo;
