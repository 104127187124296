import { FunctionalComponent, h } from 'preact';
import style from './Footer.scss';
import { urlFormat } from '../../utils/url-format';

const date = new Date();

const socials = [
    {url: 'https://www.facebook.com/EngViewSystems', icon: urlFormat('assets/facebook.svg')},
    {url: 'https://twitter.com/EngviewSystems', icon: urlFormat('assets/twitter.svg')},
    {url: 'https://www.linkedin.com/company/engview-systems-sofia', icon: urlFormat('assets/linkedin.svg')},
    {url: 'https://www.youtube.com/channel/UCOEfDxYPzSgtPtYa6mIKUaQ', icon: urlFormat('assets/youtube.svg')},
];

const Footer: FunctionalComponent = () => {
    return (
        <footer className={'bg-primary px-4 lg:px-0 ' + style.footer}>
            <div className={'main-container flex flex-col-reverse lg:flex-row justify-between items-center h-full'}>
                <div className={'text-white'}>{date.getFullYear()}©EngView Systems</div>
                <div className={'mb-4 lg:mb-0'}>
                    <ul className={'list-none space-x-1 flex'}>
                        {socials.map((social) => {
                            return (
                                <li className={style.social}>
                                    <a href={social.url}>
                                        <img src={social.icon} alt="icon"/>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <a className={'mb-4 lg:mb-0 block'} href='https://www.engview.com'>
                    <img className={style.logo} src={urlFormat('assets/footer-logo.svg')} alt="EngView Systems"/>
                </a>
            </div>
        </footer>
    );
};

export default Footer;
