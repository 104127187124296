import { FunctionalComponent, h } from 'preact';
import MainSection from '../../components/main-section/MainSection';
import ImageSection from '../../components/image-section/ImageSection';
import ImageSectionText from '../../components/image-section/image-section-text/ImageSectionText';
import ImageSectionImage from '../../components/image-section/image-section-image/ImageSectionImage';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import { urlFormat } from '../../utils/url-format';

const images = [
    {
        id: 'producttour',
        classNames: 'flex-col-reverse lg:flex-row',
        left: <ImageSectionImage image={urlFormat('assets/section1.jpg')}  class={'mt-5'} />,
        right: <ImageSectionText title={'Less time for estimations and more time for production'}  texts={['Preparing cost estimations for new projects can take up most of your day, and only part of those estimations will turn into orders. Having automatic dieboard cost estimations gives you time to focus on production.',
            'With the Dieboard Calculator your customers no longer wait for quotations but get instant automatic cost for their projects.']}/>
    },
    {
        classNames: '',
        left: <ImageSectionText title={'Get detailed and professionally prepared job requests'}
                                class={'lg:pl-7'}
                                texts={['Well defined projects with correct drawings reduce the chance for mistakes or miscommunication with the customers and save you valuable time and resources.',
            'With the Dieboard Calculator your customers can create their projects online, choose a box, fill in the right sizes, create a layout and get an instant quotation for their dieboard design.']}/>,
        right: <ImageSectionImage image={urlFormat('assets/section2.jpg')}  class={'mt-5'} />,
    },
    {
        classNames: 'flex-col-reverse lg:flex-row',
        left: <ImageSectionImage image={urlFormat('assets/section3.jpg')}  class={'mt-5'} />,
        right: <ImageSectionText title={'Extremely easy to get started!'}  texts={['Get in touch with us to create your free account and add your branding and production capabilities.',
            'Then link your new Dieboard Calculator page to your website and start offering your customers instant dieboard estimations.']}/>,
    },
    {
        classNames: '',
        left: <ImageSectionText title={'Turn your costs estimates into job requests'}
                                class={'lg:pl-7'}
                                texts={['Most of the cost inquiries turn into actual projects that you can accept/decline or start a communication with the customer to clear details. When you accept an order request, you can download the drawings in various formats like EVD, DXF, CF2, DDE, and proceed to production.']}/>,
        right: <ImageSectionImage image={'assets/section4.jpg'}  class={'mt-5'} />,
    },
    {
        id: 'pricing',
        classNames: 'flex-col-reverse lg:flex-row',
        left: <ImageSectionImage image={urlFormat('assets/section5.jpg')}  class={'mt-5'} />,
        right: <ImageSectionText title={'Pay per use/No installation fee'}  texts={['Creating a diemaker\'s account is free. ',
            'There is no fee for your customers to use the Dieboard Calculator. You only pay for the accepted order requests. After the customer sees the estimated price for the their project, they place an order request to the diemaker. You only pay a small fee for the order requests that you\'ve accepted.']}/>,
    },
];

const Home: FunctionalComponent = () => {
    return (
        <div>
            <MainSection />
            <div class={'main-container px-4 lg:px-0 pt-4 lg:pt-9 space-y-13 lg:space-y-26.5 mb-12.5'}>
                {images.map(({left, right, id, classNames}) => {
                    return (
                        <div>
                            {id ? <a id={id}></a> : null}
                            <ImageSection left={left} right={right} class={classNames} />
                        </div>
                    );
                })}
            </div>
            <a id='contactus'></a>
            <ContactUsForm />
        </div>
    );
};

export default Home;
